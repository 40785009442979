import React, { useContext } from 'react';
import { useInView } from 'react-intersection-observer';

import PropTypes from 'prop-types';
import styles from './CardList.module.scss';
import classNames from 'classnames';
import Button from '../Button';
import Card from '../Card';
import dynamic from 'next/dynamic';
import { TrackingContext } from '../StreamField/StreamField';
import { trackCtaClickStartpage } from '../../utils/datalayer';

const CardSlider = dynamic(() => import('../CardSlider'));

const CardList = ({
    link,
    items,
    preamble,
    title,
    visibleSlides,
    color,
    modifiers,
}) => {
    const bannerLevel = useContext(TrackingContext);

    if (!items || !items.length) {
        return null;
    }

    const domRef = React.useRef();
    const hasLink = Boolean(link);
    let cardClass = '';

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    cardClass = classNames(
        modifiers.map((mod) => styles['CardList--' + mod]),
        styles['CardList'],
        {
            [styles[`CardList--SpaceBetween`]]: items.length > 3,
            [styles[`CardList--Slider`]]: items.length > 4,
            [styles[`CardList--` + color]]: color,
        }
    );

    return (
        <div className={cardClass} ref={ref}>
            <div
                className={classNames(styles['CardList__ContentContainer'], {
                    [styles['CardList__ContentContainer--isVisible']]: inView,
                })}>
                <div
                    className={classNames(styles['CardList__TextContainer'], {
                        [styles['CardList__TextContainer--Shape']]:
                            [preamble && color == 'White']
                            [preamble && color == 'Half'],
                    })}>
                    {
                        <div className={styles['CardList__TitleContainer']}>
                            {title && (
                                <h2 className={styles['CardList__Title']}>
                                    {title}
                                </h2>
                            )}
                            <div ref={domRef} />
                        </div>
                    }

                    {preamble && (
                        <div className={styles['CardList__PreambleContainer']}>
                            <div className={styles['CardList__Preamble']}>
                                {preamble}
                            </div>
                        </div>
                    )}
                    {link && (
                        <div
                            className={
                                styles['CardList__LinkContainer']
                            }>
                            <Button
                                modifiers={[styles['CardList__Button']]}
                                onClick={() => {
                                    if (bannerLevel) {
                                        trackCtaClickStartpage(
                                            bannerLevel,
                                            'show more button',
                                            link.label
                                        );
                                    }
                                }}
                                href={link.href}
                                text={link.label}
                                type="tertiary"
                                icon="arrow"
                                iconSize="large"
                                iconColor={
                                    color === 'White' ? 'pink' : 'white'
                                }
                                buttonColor={
                                    color === 'White' ? 'pink' : 'white'
                                }
                            />
                        </div>
                    )}
                </div>

                {Boolean(items.length) &&
                    (items[0].size === 'xlarge' ? (
                        <Featured items={items} />
                    ) : (
                        <CardSlider
                            visibleSlides={visibleSlides}
                            className={classNames({
                                'CardSlider__Slider--Link': hasLink,
                            })}
                            items={items}
                            color={color}
                        />
                    ))}
            </div>
        </div>
    );
};

CardList.propTypes = {
    items: PropTypes.array,
    title: PropTypes.string,
    preamble: PropTypes.string,
    link: PropTypes.object,
    visibleSlides: PropTypes.number,
    color: PropTypes.string,
    modifiers: PropTypes.array,
};

CardList.defaultProps = {
    items: [],
    title: null,
    preamble: null,
    link: null,
    visibleSlides: null,
    color: '',
    modifiers: [],
};

const Featured = ({ items, visibleSlides, color }) => {
    const featuredCards = items.filter((item) => item.size === 'xlarge');
    const cards = items.filter((item) => item.size !== 'xlarge');

    const cardClassFeatured = classNames(
        styles['CardList'],
        styles[`CardList--` + color],
        styles[`CardList--Featured`],
        {
            [styles[`CardList--SpaceBetween`]]: cards.length > 3,
        }
    );

    return (
        <div className={cardClassFeatured}>
            {Boolean(featuredCards.length) && (
                <div className={classNames(styles['CardList__FeaturedCards'])}>
                    {featuredCards.map((card, i) => {
                        return (
                            <Card
                                {...card}
                                size={
                                    featuredCards.length === 1
                                        ? 'full'
                                        : card.size
                                }
                                key={i}
                                color={color}
                            />
                        );
                    })}
                </div>
            )}
            {Boolean(cards.length) && (
                <CardSlider
                    visibleSlides={visibleSlides ? visibleSlides : 1}
                    items={cards}
                    color={color}
                />
            )}
        </div>
    );
};

Featured.propTypes = {
    items: PropTypes.array,
    modifiers: PropTypes.array,
    color: PropTypes.string,
};

Featured.defaultProps = {
    items: null,
    modifiers: [],
    color: '',
};

export default CardList;
